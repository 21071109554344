//THIS SECTION IS NOT USED OR NEEDED IN BOOKMERLIN!!!!!!!!

import React from "react";

import { Link } from 'gatsby';

import withStyles from "@material-ui/core/styles/withStyles";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class HomeDescriptionSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer >
          <GridItem justify="center">
            <ul>
                <li className={classes.title}>Talking Point 1. I need these to be longer so that I can see how it'll look wih more words.</li>
                <li className={classes.title}>Talking Point 2. And more sentences and some will be longer than others and now this is a runon sentence. Is runon a word or should it be run on. Probably run on.</li>
                <li className={classes.title}>Talking Point 3. But I've never been good at English. Math and Science all the way. And now coding because I am the bomb.</li>
            </ul>
            <Link to='/registerPage'>
                <Button>Get Started!</Button>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(productStyle)(HomeDescriptionSection);
